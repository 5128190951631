@import "../../assets/stylesheets/core";

.sg-content-section {
  &.instruction-screen-section {
    // padding: 7rem 3rem 3rem;
      padding: 0.5rem 3rem;

    .camera-icon {
      width: 2.5rem;
      height: auto;
    }

    .sg-logo {
      width: 15rem;
    }

    .bg-options-img {
      width: 9rem;
      height: auto;
    }

    .get-started-btn {
      padding: 0.5rem 3.75rem;
    }
  }
}