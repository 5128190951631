$black: #000000;
$white: #ffffff;

$gray-bright: #e9ecef;
$gray-ash: #6C757D;
$blue-prussian: #00204A;
$tangaroa: #022043;
$blue-denim : #137FC0;
$blue-sky: #6CC5EF;
$blue-seagull: #7EC7ED;
$blue-pale-cornflower: #B1DBF5;
$yellow-gold: #FDD303;
$red: #f00;

$blue: $blue-prussian;
$yellow: $yellow-gold;
$cyan: $blue-denim;

$colors: (
  "blue": $blue,
  "yellow": $yellow,
  "cyan": $cyan,
);
// Theme Colors Overrides
$primary : $yellow;
$secondary: $blue;
$info: $cyan;

// Prefix for :root CSS variables
$variable-prefix: sg-;

// Font
$font-family-sans-serif: 'Gilroy';

$font-family-base: var(--#{$variable-prefix}font-sans-serif);

$body-color: $white;
$body-bg: rgba($secondary, 0.95);

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-mini: $font-size-base * 0.625;
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-md: $font-size-base * 1.125;
$font-size-lg: $font-size-base * 1.25;
$font-size-large: $font-size-base * 3.25;

//Link Color

$link-color:                        $cyan;

//Form Range
$form-range-track-height:                   0.125rem;
$form-range-track-bg:                       $white;
$form-range-thumb-bg:                       $cyan;
$form-range-thumb-box-shadow:               none;
$form-range-thumb-focus-box-shadow:         none;
$form-range-thumb-focus-box-shadow-width:   0;
$form-range-thumb-active-bg:                $cyan;

// scss-docs-start form-input-variables
$input-font-size:                       $font-size-sm;
$input-placeholder-color:               $tangaroa;
$input-color:                           $tangaroa;
$input-disabled-color:                  $gray-ash !important;
$input-disabled-bg:                     $gray-bright !important;