@import "../../assets/stylesheets/core";

.sg-text-editor {
  .form-control {
    background-color: $white;
  }
}

.shuffle-icon {
  width: 1.5rem;
}