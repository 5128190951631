@import "../../assets/stylesheets/core";

//Adjust camera size as per required screen
.device-camera{
    width: 100% ;
    height: 100% ;
    object-fit: cover ;
}

.btn-camera{
    width: 80px;
    height: 80px;
    margin-bottom: 1rem;
}

.error_msg{
    color: red;
    text-align: center;
}


.camera-action-btn {
    .img {
        width: 2.5rem;
        height: auto;
    }
}