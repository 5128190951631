@import "../../assets/stylesheets/core";

.preview-screen-title {
  font-size: 2.375rem;
}

.sg-share-modal {
  .modal-content {
    p {
      letter-spacing: -0.056rem;

      >i {
        vertical-align: middle;
      }
    }
  }

  .share-modal-title {
    font-size: 2.375rem;
    letter-spacing: -0.76px;
  }
}

.sg-edit-action-section {
  .preview-action-icon {
    width: 1.5rem;
  }
}

.preview-image-box {
  width: 100%;
  // height: 26.25rem;
  // max-height: 25rem;

  .preview-img {
    // transform: scale(0.85,0.85);
    border: 4px solid $white;
  }

  &.billboard-preview-image-box {
    background: linear-gradient(90deg, #071F46 0%, #183457 8.34%, #345D85 16.98%, #345D85 84.33%, #183457 92.06%, #06294F 100%);
    .preview-img {
      border: none;
    }
  }
  
}

.tooltip-inner {
  max-width: 80vw;
  word-wrap: break-word;
}

.preview-page {
  .sg-edit-action-section  {
    height: unset;
    max-height: unset;
  }
}

.festive-link-wrap {
  .arrow-img {
    width: 1.25rem;
    height: 1.25rem;
  }
}