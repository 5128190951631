@import "../../assets/stylesheets/core";

.user-info-wrapper {
  p {
    letter-spacing: -0.056rem;
  }
  
  .user-info-tile {
    border-color: $white;
    border-radius: 1.25rem;
  }

  .preview-final-img {
    height: 17rem;
  }
}

.placeholder-white::placeholder{
  color: white;
}

.custom-link {
  color: inherit;
  text-decoration: underline;
}

Col{
  padding: 0 !important;
}

.padding {
  padding-top: 30px;
}

.no-wrapper {
  white-space: pre;
  overflow: hidden;
}