@import "../../assets/stylesheets/core";

//Adjust captured photo size as per required screen
// .captured-photo{
//     width: 100%;
//     height: auto;
//     object-fit: cover;
// }

.black-white-filter {
  filter: grayscale(100%);
}

.vibrant-filter{
  filter: brightness(1.1) contrast(1.2); /* Adjust the values as per your preference */
}

.none-filter{
  filter: none;
}

.sg-text-variation-wrapper {
  .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white;
    opacity: 1; /* Firefox */
  }

  .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $white;
  }

  .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: $white;
  }
}

.sg-text-variation-wrapper {

  z-index: 9999;
  height: 100%;
  pointer-events: none;

  .user-caption-wrap {
    top: 1.5rem;

    .user-caption {
      font-size: 2.75rem;
    }
  }

  .user-info { 
    width: 90%;
    left: 1.5rem;
    bottom: 0.25rem;
    
    .name-label-wrap {
      letter-spacing: 1.5px;
      line-height: 1;
    }
    
    .user-name-wrap {

      .user-name {
        font-size: 2rem;
        font-weight: 600;
        line-height: 0;
      }
      .user-last-name {
        margin-top: -0.5rem;
      }
    }
  }

  .shuffle-img {
    width: 16rem;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 0.5rem;
  }

  &._variation-type1 {
  
    .shuffle-img {
      // width: 15.5rem;
      // height: auto;
      // left: 1.5rem; 
    }

    .user-caption {
      font-family: 'Impact';
      text-transform: uppercase;
      color: $white;
    }

    // .user-info {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   bottom: 0;

    //   .name-label-wrap {
    //     span {
    //       font-size: $font-size-xs;
    //       color: $white;
    //     }
    //   }

    //   .user-name-wrap {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;

    //     .form-control {
    //       text-align: center;
    //     }
    //   }
    // }

  }


  &._variation-type2 {

    .shuffle-img {
      // width: 20rem;
      // height: auto;
      // left: 50%;
      // transform: translateX(-50%);
    }

    .user-caption {
      font-family: 'Lato', sans-serif;
      color: $white;
      text-transform: uppercase;

      ::placeholder {
        color: $white;
      }
    }

    .form-control.user-caption::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white;
      opacity: 1; /* Firefox */
    }

    .form-control.user-caption:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $white;
    }

    .form-control.user-caption::-ms-input-placeholder { /* Microsoft Edge */
      color: $white;
    }

    .name-label-wrap {
      span {
        text-align: center;
      }
    }
    .user-info {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
      .user-name-wrap {
        .user-name {
          text-align: center;
        }
      }
    }

    // .name-label-wrap {
    //   display: flex;
    //   justify-content: flex-end;
    //   background: $black;


    //   span {
    //     font-family: Impact;
    //     writing-mode: vertical-lr;
    //     font-size: 1.25rem;
    //     color: $red;
    //     margin-left: auto;
    //   }
    // }

    // .user-name-wrap {
    //   .form-control{
    //     font-size: 2.5rem;
    //     font-weight: 600;
    //     color: $white;
    //     line-height: 3.125rem;
    //   }
    // }
  }

  &._variation-type3 {

    .shuffle-img {
      // width: 19.5rem;
      // height: auto;
      // right: 1.5rem;
    }

    .user-caption {
      font-family: 'Gochi Hand', cursive;
      color: #EC5852;
      text-shadow: -1px 0 $white, 0 1px $white, 1px 0 $white, 0 -1px $white;
    }
    .form-control.user-caption::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #EC5852;
      text-shadow: -1px 0 $white, 0 1px $white, 1px 0 $white, 0 -1px $white;
      opacity: 1; /* Firefox */
    }

    .form-control.user-caption:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #EC5852; 
      text-shadow: -1px 0 $white, 0 1px $white, 1px 0 $white, 0 -1px $white;
    }

    .form-control.user-caption::-ms-input-placeholder { /* Microsoft Edge */
      color: #EC5852;      
      text-shadow: -1px 0 $white, 0 1px $white, 1px 0 $white, 0 -1px $white;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      right: 1rem;

      .name-label-wrap {
        text-align: right;
        // span {
        //   font-size: 1rem;
        //   color: $white;
        // }
      }

      .user-name-wrap {
        .form-control {
          text-align: right;
        }
      }
    }
  }
}

//React cropper
.sg-react-cropper {
  .reactEasyCrop_Container {
    height: 100%;
    top: unset;
  }
  .reactEasyCrop_CropArea{
    height: 100% !important;
  }

  .reactEasyCrop_Image {
    width: 100% !important;
    object-fit: contain;
    height: 100% !important;
  }
}

.capture-edit-wrapper {
  .sg-edit-action-section {
    min-height: 12.5rem;
    max-height: unset;
  }

  .preview-box {
    // max-height: calc(100vh - 12.5rem);
    // height: 33.5rem;
    // width: 80%;
    margin: 0 auto;
    &.preview-box-billboard {
      background-color: rgb(214, 152, 78);
    }
  }
}

.edit-outline-box {
  // width: 80%;
  height: 100%;
  margin: 0 auto;
}

.edit-box-section {
  .sg-image-container {
    border: 4px solid $white;
  }

  &.edit-box-billboard-section {
    background: radial-gradient(circle, rgba(255,255,255,1) 33%, rgba(108,197,239,1) 90%);

    .sg-image-container {
      border: none;
    }
  }
}
.sg-image-container {
  // height: 33.5rem;
  height: 100%;

  .sg-playbill-img {
    height: 5.75rem;
  }

  .preview-wrap {
    height: calc(100% -  5.75rem);
    // height: 100%;
  }

  .sg-user-img{
    height: 100%;
    object-fit: cover;
    bottom: 0;
    z-index: 0;
  } 
}

// .reactEasyCrop_Image{
// z-index: 99999 !important;
//   background-color: #fff; /* Set the desired background color */

// }